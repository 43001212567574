import React from 'react'
import {observer} from 'mobx-react-lite'

import {AdProvider} from '@rambler-components/ad'
import type {ChannelCategory} from 'types/category'
import type {Runtime} from 'types/runtime'
import type {City} from 'types/cities'

import {
  MOBILE_PADID,
  AD_PROMO_MOBIBLE_ID,
  PUBLISHER_SCOPE,
  AD_TOPBANNER_ID,
  AD_FOOTER_ID,
  AD_RICH_ID
} from 'common/constants/ad'

import {LayoutCommon, type LayoutProps} from 'common/components/layout'
import {ToplineMobile} from 'mobile/components/topline'
import {Ad} from 'common/components/ad'
import {CategoryNavigationMobile} from 'mobile/components/category-navigation'
import {Footer} from 'common/components/footer'

import styles from './styles.module.css'

interface LayoutMobileProps {
  runtime: Runtime
  categories: ChannelCategory[]
  routeParams: {city: City; date: Date}
  adParams: {
    adTransitionCounter: number
    routeName: string
    isAdWidgetEnabled: boolean
  }
  children: React.ReactNode
}

export const LayoutMobile: React.FC<LayoutMobileProps> = ({
  runtime,
  categories,
  routeParams,
  adParams,
  children
}) => (
  <AdProvider
    padId={MOBILE_PADID}
    publisherId={runtime.ruid}
    publisherScope={PUBLISHER_SCOPE}>
    {adParams.isAdWidgetEnabled && (
      <Ad id={AD_PROMO_MOBIBLE_ID} preloadHeight={125} adParams={adParams} />
    )}
    <ToplineMobile />
    <Ad
      id={AD_TOPBANNER_ID}
      className={styles.topbannerAd}
      preloadHeight={250}
      adParams={adParams}
    />
    <Ad id={AD_RICH_ID} adParams={adParams} />
    <CategoryNavigationMobile
      categories={categories}
      routeParams={routeParams}
      top100={`${adParams.routeName}_page`}
    />
    <main className={styles.main}>{children}</main>
    <Ad
      id={AD_FOOTER_ID}
      className={styles.footerAd}
      preloadHeight={250}
      adParams={adParams}
      isLazy
    />
    <Footer isMobile />
  </AdProvider>
)

export const LayoutCommonMobile: React.FC<LayoutProps> = observer(
  ({runtime, stores, error, children}) => {
    return (
      <LayoutCommon runtime={runtime} error={error} stores={stores}>
        <LayoutMobile
          runtime={runtime}
          categories={stores.categoryStore.categories}
          routeParams={stores.routeParamsStore.routeParamsForPage}
          adParams={stores.routeParamsStore.routeParamsForAd}>
          {children}
        </LayoutMobile>
      </LayoutCommon>
    )
  }
)
