import {ChannelPage} from './channel-page'
import {MainPage} from './main-page'
import {ShowPage} from './show-page'
import {PremierePage} from './premiere-page'
import {FavoritePage} from './favorite-page'
import {FavoriteShows} from './favorite-shows'
import {NewsList} from './news-list'

export const Skeleton = {
  MainPage,
  ChannelPage,
  ShowPage,
  PremierePage,
  FavoritePage,
  FavoriteShows,
  NewsList
}
