import React from 'react'

import clsx from 'clsx'
import type {ChannelCategory} from 'types/category'
import type {City} from 'types/cities'

import {CONSTANT_CHANNEL_CATEGORIES} from 'common/constants/category-navigation'
import {getUrlForCategory} from 'common/utils/url'

import {NavLink} from 'common/components/nav-link'

import styles from './styles.module.css'

interface CategoryNavigationMobileProps {
  categories: ChannelCategory[]
  routeParams: {city: City; date: Date}
  top100?: string
}

export const CategoryNavigationMobile: React.FC<
  CategoryNavigationMobileProps
> = ({categories: channelCategories, routeParams, top100}) => {
  const categories = CONSTANT_CHANNEL_CATEGORIES.map((category) => ({
    ...category,
    nameTranslit: category.nameTranslit.replace(
      ':city',
      routeParams.city.nameTranslit
    )
  })).concat(channelCategories)

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {categories.map(({id, name, nameTranslit}, index) => (
          <NavLink
            key={id}
            to={
              index < CONSTANT_CHANNEL_CATEGORIES.length
                ? nameTranslit
                : getUrlForCategory({
                    city: routeParams.city,
                    date: routeParams.date,
                    categoryTranslit: nameTranslit
                  })
            }
            className={({isActive}) =>
              clsx(styles.link, isActive && styles.active)
            }
            data-tv-mobile={`${top100}::nav_menu::nav_item::${index + 1}`}>
            {name}
          </NavLink>
        ))}
      </div>
    </div>
  )
}
