/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import clsx from 'clsx'

import {Blocks} from './blocks'

import styles from './styles.module.css'

export const ShowPage: React.FC = () => {
  return (
    <div>
      <div className={styles.show}>
        <div className={styles.showTitle}>
          <Blocks count={2} />
        </div>
        <div className={clsx(styles.image, styles.animated, styles.item)} />
        <div className={styles.showActions}>
          <Blocks count={3} />
        </div>
        <div
          className={clsx(styles.showButton, styles.item, styles.animated)}
        />
      </div>
      <div className={styles.showChannel}>
        <Blocks count={2} />
      </div>
      <div className={styles.showSelection}>
        <Blocks count={2} />
      </div>
      <div className={styles.showSelection}>
        <Blocks count={2} />
      </div>
    </div>
  )
}
