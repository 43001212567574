/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import clsx from 'clsx'

import {Blocks} from './blocks'

import styles from './styles.module.css'

export const PremierePage: React.FC = () => {
  return (
    <div>
      <div className={styles.content}>
        <div className={clsx(styles.pageTitle, styles.animated, styles.item)} />
        <div className={clsx(styles.image, styles.animated, styles.item)} />
        <div className={styles.premiereTitle}>
          <Blocks count={2} />
        </div>
        <div className={styles.premiereCategories}>
          <Blocks count={2} />
        </div>
        <div className={styles.premiereText}>
          <Blocks count={8} />
        </div>
        <div className={styles.premiereTime}>
          <Blocks count={3} />
        </div>
      </div>
    </div>
  )
}
