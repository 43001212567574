/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'

import {Blocks} from './blocks'

import styles from './styles.module.css'

export const ChannelPage: React.FC = () => {
  return (
    <div>
      <div className={styles.channelInfo}>
        <div className={styles.channelTitle}>
          <Blocks count={2} />
        </div>
        <div className={styles.channelActions}>
          <Blocks count={3} />
        </div>
        <div className={styles.channelText}>
          <Blocks count={10} />
        </div>
      </div>
      <div className={styles.categories}>
        <Blocks count={4} />
      </div>
      <div className={styles.selection}>
        <Blocks count={4} />
      </div>
    </div>
  )
}
