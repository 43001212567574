import React, {useState} from 'react'

import RamblerToplineMobile from '@rambler-components/topline-mobile'
import RamblerGeo from '@rambler-components/geo'
import {Popup} from 'common/components/popup'
import {MOBILE_LINKS, MOBILE_SOCIAL} from './constants'

import '@rambler-components/topline-mobile/styles.css'
import '@rambler-components/geo/styles.css'

export const ToplineMobile: React.FC = () => {
  const [isGeoOpened, setGeoOpened] = useState(false)

  return (
    <>
      <RamblerToplineMobile
        projectCode="tv"
        simpleLinks={MOBILE_LINKS}
        socialLinks={MOBILE_SOCIAL}
        showLegalRules
        onOpenGeo={() => setGeoOpened(true)}
      />

      <Popup
        isFullPage
        isOpen={isGeoOpened}
        onClose={() => setGeoOpened(false)}>
        <RamblerGeo onClose={() => setGeoOpened(false)} />
      </Popup>
    </>
  )
}
