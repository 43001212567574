/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import clsx from 'clsx'

import {Blocks} from './blocks'

import styles from './styles.module.css'

export const MainPage: React.FC = () => {
  return (
    <div>
      <div className={styles.title}>
        <div className={clsx(styles.item, styles.animated)} />
      </div>
      <div className={styles.categories}>
        <Blocks count={4} />
      </div>
      <div className={styles.row}>
        <div className={clsx(styles.item, styles.animated, styles.select)} />
        <div className={clsx(styles.item, styles.animated, styles.button)} />
      </div>
      <div className={styles.list}>
        <Blocks count={10} />
      </div>
    </div>
  )
}
