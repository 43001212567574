/* eslint-disable sonarjs/no-duplicate-string */
import {lazy, type PageRoute} from '@rambler-tech/react-toolkit/client'
import {Urls} from 'common/constants/urls'
import {Skeleton} from 'mobile/components/skeleton'

const MainPageMobile = lazy(
  () => import(/* webpackChunkName: "mobile.main" */ 'mobile/pages/main')
)
const FavoritePageMobile = lazy(
  () =>
    import(/* webpackChunkName: "mobile.favorite" */ 'mobile/pages/favorite')
)
const ChannelPageMobile = lazy(
  () => import(/* webpackChunkName: "mobile.channel" */ 'mobile/pages/channel')
)
const ShowPageMobile = lazy(
  () => import(/* webpackChunkName: "mobile.show" */ 'mobile/pages/show')
)
const PremierePageMobile = lazy(
  () =>
    import(/* webpackChunkName: "mobile.premiere" */ 'mobile/pages/premiere')
)

export const routes: (PageRoute & {chunkName: string})[] = [
  {
    path: Urls.MAIN,
    Component: MainPageMobile,
    Fallback: Skeleton.MainPage,
    chunkName: 'mobile.main'
  },
  {
    path: Urls.CITY,
    Component: MainPageMobile,
    Fallback: Skeleton.MainPage,
    chunkName: 'mobile.main'
  },
  {
    path: Urls.YESTERDAY,
    Component: MainPageMobile,
    Fallback: Skeleton.MainPage,
    chunkName: 'mobile.main'
  },
  {
    path: Urls.TODAY,
    Component: MainPageMobile,
    Fallback: Skeleton.MainPage,
    chunkName: 'mobile.main'
  },
  {
    path: Urls.TOMORROW,
    Component: MainPageMobile,
    Fallback: Skeleton.MainPage,
    chunkName: 'mobile.main'
  },
  {
    path: Urls.WEEK,
    Component: PremierePageMobile,
    Fallback: Skeleton.PremierePage,
    chunkName: 'mobile.premiere'
  },
  {
    path: Urls.WEEKEND,
    Component: PremierePageMobile,
    Fallback: Skeleton.PremierePage,
    chunkName: 'mobile.premiere'
  },
  {
    path: Urls.DATE,
    Component: MainPageMobile,
    Fallback: Skeleton.MainPage,
    chunkName: 'mobile.main'
  },
  {
    path: Urls.PERSONAL,
    Component: FavoritePageMobile,
    Fallback: Skeleton.FavoritePage,
    chunkName: 'mobile.favorite'
  },
  {
    path: Urls.CATEGORY,
    Component: MainPageMobile,
    Fallback: Skeleton.MainPage,
    chunkName: 'mobile.main'
  },
  {
    path: Urls.CHANNEL,
    Component: ChannelPageMobile,
    Fallback: Skeleton.ChannelPage,
    chunkName: 'mobile.channel'
  },
  {
    path: Urls.CHANNEL_DATE,
    Component: ChannelPageMobile,
    Fallback: Skeleton.ChannelPage,
    chunkName: 'mobile.channel'
  },
  {
    path: Urls.SHOW,
    Component: ShowPageMobile,
    Fallback: Skeleton.ShowPage,
    chunkName: 'mobile.show'
  },
  {
    path: Urls.EPISODE,
    Component: ShowPageMobile,
    chunkName: 'mobile.episode'
  }
]
