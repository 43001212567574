import type {SocialLinkType, LinkType} from '@rambler-components/topline-mobile'
import {changeViewport} from 'common/utils/change-viewport'

export const MOBILE_SOCIAL: SocialLinkType[] = [
  {
    url: 'https://vk.com/rambler',
    code: 'vk'
  },
  {
    url: 'https://ok.ru/rambler',
    code: 'ok'
  },
  {
    url: 'tg://resolve?domain=news_rambler',
    code: 'tg'
  }
]

export const MOBILE_LINKS = [
  {
    label: 'Полная версия',
    onClick: () => changeViewport({isMobile: false})
  },
  {
    label: 'Политика конфиденциальности',
    url: 'https://help.rambler.ru/legal/1142/'
  },
  {
    label: 'Условия использования',
    url: 'https://help.rambler.ru/legal/1430/'
  }
] as LinkType[]
