/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import clsx from 'clsx'

import {Blocks} from './blocks'

import styles from './styles.module.css'

export const FavoritePage: React.FC = () => {
  return (
    <div>
      <div className={clsx(styles.content, styles.withBorder)}>
        <div
          className={clsx(styles.animated, styles.item, styles.favoriteTitle)}
        />
        <div className={styles.premiereTitle}>
          <Blocks count={2} />
        </div>
        <div
          className={clsx(
            styles.image,
            styles.animated,
            styles.item,
            styles.favorite
          )}
        />
        <div className={clsx(styles.animated, styles.item, styles.subtitle)} />
        {new Array(3).fill('').map((_, index) => (
          <div key={index} className={styles.favoriteSchedule}>
            <Blocks count={2} />
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Blocks count={2} />
      </div>
    </div>
  )
}
